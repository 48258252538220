/* FormPage.css */
.form-page {
    max-width: 600px;
    margin: 0 auto;
    margin-top: 3%;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .form-page h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-page .message {
    margin-bottom: 20px;
    padding: 10px;
    background-color: #d4edda;
    border: 1px solid #c3e6cb;
    border-radius: 4px;
    color: #155724;
  }
  
  .form-page form {
    display: flex;
    flex-direction: column;
  }
  
  .form-page label {
    margin-bottom: 5px;
    font-weight: 600;
  }
  
  .form-page input[type="text"],
  .form-page input[type="email"],
  .form-page textarea {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .form-page textarea {
    resize: vertical;
  }
  
  .form-page input[type="checkbox"] {
    margin-right: 10px;
  }
  
  .form-page .submit-btn {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .form-page .submit-btn:hover {
    background-color: #0056b3;
  }
  
  .form-page a {
    display: inline-block;
    margin-bottom: 20px;
    color: #007bff;
    text-decoration: none;
  }
  
  .form-page a:hover {
    text-decoration: underline;
  }
  