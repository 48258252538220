/* AddedCity.css */
.added-cities-container {
    padding: 20px;
    text-align: center; /* Center align the container content */
  }
  
  .added-cities-container h2 {
    margin-bottom: 20px;
  }
  
  .city-list {
    list-style-type: none;
    padding: 0;
  }
  
  .city-item {
    margin-bottom: 10px;
    display: flex;          /* Use flexbox for the items */
    justify-content: center; /* Center align the items */
  }
  
  .city-button {
    display: inline-block;  /* Use inline-block to respect the button width */
    padding: 10px 15px;
    border: 2px solid #1d4ed8;
    border-radius: 4px;
    color: #1d4ed8;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    width: 50%;             /* Button width */
    transition: background-color 0.3s, color 0.3s;
  }
  
  .city-button:hover {
    background-color: #1d4ed8;
    color: white;
  }
  